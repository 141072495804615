import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../components/header_resources"

import ToolsPic1 from "../../static/images/tools/tools_01-560x320.jpg"
import ToolsPic2 from "../../static/images/tools/tools_02-560x320.jpg"
import ToolsPic3 from "../../static/images/tools/tools_03-560x320.jpg"

export default function Tools() {
  const heroTitle = "Tools"

  return (
    <HeaderResources heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <div class="row">
        <div class="col-12 col-lg-4 col-md-4">
          <Link to="/resources/tools/risk-profile" class="post-wrapper">
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img src={ToolsPic1} alt="Risk Profile" class="img-fluid" />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h4 h4-sm mb-1">Risk Profile</h3>
              <p class="text--grey">
                Combined with other assessment techniques, this questionnaire...
              </p>
            </div>
          </Link>
        </div>

        <div class="col-12 col-lg-4 col-md-4">
          <Link to="/resources/tools/5-minute-health-check" class="post-wrapper">
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img src={ToolsPic2} alt="5 Minute Financial Health Check" class="img-fluid" />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h4 h4-sm mb-1">5 Minute Financial Health Check</h3>
              <p class="text--grey">
                A lot of people aren't really sure where their finances stand at the best of
                times....
              </p>
            </div>
          </Link>
        </div>

        <div class="col-12 col-lg-4 col-md-4">
          <Link to="/resources/tools/investment-advisory" class="post-wrapper">
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img src={ToolsPic3} alt="Value of Advice" class="img-fluid" />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h4 h4-sm mb-1">Investment Advisory</h3>
              <p class="text--grey">
                Your feedback helps us to improve our business by making it more valuable...
              </p>
            </div>
          </Link>
        </div>
      </div>
    </HeaderResources>
  )
}
